import * as React from "react";

// Load fonts
import "./src/fonts/fonts.css";

import { ShopifyProvider, CartProvider } from "@shopify/hydrogen-react";

const CART_FRAGMENT = `#graphql
  fragment CartFragment on Cart {
    updatedAt
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: $numCartLines) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...CartApiMoney
              }
              price {
                ...CartApiMoney
              }
              requiresShipping
              title
              image {
                ...CartApiImage
              }
              product {
                handle
                title
                id
                vendor
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...CartApiMoney
      }
      totalAmount {
        ...CartApiMoney
      }
      totalDutyAmount {
        ...CartApiMoney
      }
      totalTaxAmount {
        ...CartApiMoney
      }
    }
    note
    attributes {
      key
      value
    }
    discountAllocations {
      discountApplication {
        allocationMethod 
        targetSelection
        targetType
        value {
          ... on PricingPercentageValue {
            percentage
          }
          ... on MoneyV2 {
            amount
            currencyCode
          }
        }
      }
      discountedAmount {
        amount
        currencyCode
      }
    }
    discountCodes {
      applicable
      code
    }
  }

  fragment CartApiMoney on MoneyV2 {
    currencyCode
    amount
  }

  fragment CartApiImage on Image {
    id
    url
    altText
    width
    height
  }
`;

export const wrapRootElement = ({ element }) => (
  <ShopifyProvider
    storeDomain="9b28f2.myshopify.com"
    storefrontToken="ba96d48eebc26ed51838e349568bcd18"
    storefrontApiVersion="2025-01"
    countryIsoCode="GB"
    languageIsoCode="EN"
  >
    <CartProvider
      cartFragment={CART_FRAGMENT}
      onError={(error) => {
        console.error("Cart error:", error);
      }}
    >
      {element}
    </CartProvider>
  </ShopifyProvider>
);
